<template>
  <div>
    <SizeSelector
      @height="setHeight"
      @width="setWidth"
      ref="sizeSelectorSlider"
      :enable_height="true"
      :enable_width="false"
      :read-only="readOnly"
    ></SizeSelector>
    <div class="addItem text-right" v-if="!readOnly">
      <span id="addItemSlider" @click="addItem()" class="material-icons"
        >library_add</span
      >
    </div>
    <SliderTabs @sliderItemsUpdate="sliderItems" ref="sliderTabs" :read-only="readOnly"></SliderTabs>
    <vs-spacer></vs-spacer>
    <div
      class="error-message height-fit-content my-3 text-center"
      v-if="errorMsg && errorMsg.length"
    >
      <p v-for="(errorObj, index) in errorMsg" :key="index">{{ errorObj }}</p>
    </div>

    <div class="mt-4">
      <BotMessageValidation :read-only="readOnly"/>
    </div>

    <div class="flex justify-center mt-4" v-if="!readOnly">
      <vs-button color="danger" type="flat" @click="onCancel">{{
        lang.botMaker.botEditor.cancelMessage[languageSelected]
      }}</vs-button>

      <vs-button
        :disabled="hasError"
        class="bg-primary-gradient ml-4 add"
        type="filled"
        @click="addMessage()"
        v-if="!botMessageInEdit.isSaved"
      >
        <span
          v-if="!hasError && !botMessageInEdit.isSaved"
          v-text="lang.botMaker.botEditor.addMessage[languageSelected]"
        ></span>

        <span
          v-if="hasError && !botMessageInEdit.isSaved"
          v-text="lang.botMaker.botEditor.addingMessage[languageSelected]"
        ></span>
      </vs-button>

      <vs-button
        :disabled="hasError"
        class="bg-primary-gradient ml-4 modify"
        type="filled"
        @click="modifyMessage()"
        v-if="botMessageInEdit.isSaved"
      >
        <span
          v-if="!hasError && botMessageInEdit.isSaved"
          v-text="lang.botMaker.botEditor.modifyMessage[languageSelected]"
        ></span>
      </vs-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import { CardMessageType } from '@/models/Messages/MessageTypes/CardMessageType'
import botMessageValidationMixin from '@/mixins/botMessageValidationMixin'

export default {
  props: {
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      slider: {
        width: '',
        height: '',
        cardsArr: []
      },
      errorLoader: [],
      errorMsg: [],
      savedCards: null,
      buttonDisabled: false,
      totalItems: [1],
      onDisabled: false,
      hasError: false
    }
  },
  mixins: [botMessageValidationMixin],
  components: {
    SizeSelector: () => import('./RCS/SizeSelector'),
    SliderTabs: () => import('./RCS/SliderTabs'),
    BotMessageValidation: () => import('../userSays/BotMessageValidation.vue')
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('botMaker', [
      'botMessageInEdit',
      'botMakerMessages',
      'botId',
      'activeChannelView',
      'userMessageInEdit'
    ]),
    uploadMessage() {
      return this.lang.botMaker.botEditor.messageType.RcsCard.addImage[
        this.languageSelected
      ]
    }
  },
  watch: {
    '$store.state.botMaker.botMessageInEdit.id'() {
      this.eraseCard()
      if (this.botMessageInEdit.type.isCardMessage()) {
        this.updateCard()
      } else {
        this.eraseCard()
      }
    }
  },
  methods: {
    ...mapActions('botMaker', ['SAVE_BOT_MESSAGE', 'GET_DIALOGS']),
    ...mapMutations('botMaker', [
      'SET_ACTIVE_CHANNEL_VIEW',
      'RESET_BOT_MESSAGE',
      'UPDATE_MESSAGE'
    ]),
    successUpload() {
      alert('SUCCESS UPLOAD')
    },
    onCancel() {
      this.errorMsg = []
      if (this.savedCards) {
        this.botMessageInEdit.type.responseCard = JSON.parse(
          JSON.stringify(this.savedCards)
        )
        this.updateCard()
      } else {
        this.eraseCard()
      }
      return true
    },
    addItem() {
      this.$refs.sliderTabs.addItem(true)
    },
    sliderItems(items) {
      this.slider.cardsArr = items.map(function(el) {
        return el
      })
    },
    setHeight(height) {
      this.slider.height = height
    },
    setWidth(width) {
      this.slider.width = width
    },
    isError() {
      return false
    },
    updateCard() {
      const cards = this.botMessageInEdit.type.responseCard
      if (!this.savedCards) this.savedCards = JSON.parse(JSON.stringify(cards))
      if (this.$refs.sizeSelectorSlider) {
        this.$refs.sizeSelectorSlider.loadHeight(cards[0].header.height)
      }
      if (this.$refs.sliderTabs) {
        this.$refs.sliderTabs.load(cards)
        this.slider.height = cards[0].header.height
      }
    },
    eraseCard() {
      this.errorMsg = []
      if (this.$refs.sliderTabs) {
        this.$refs.sliderTabs.erase()
        this.$refs.sizeSelectorSlider.erase()
      }
    },
    gT(item) {
      return this.lang.botMaker.botEditor.messageType.RcsCard.errors[item][
        this.languageSelected
      ]
    },
    async processCard(cardEntry, index) {
      const card = { ...cardEntry }
      let errorArr = this.errorLoader
      let cPos = index + 1
      let pictureFile
      const mField = this.lang.botMaker.botEditor.missingField[
        this.languageSelected
      ]
      try {
        if (!card.header.url) {
          pictureFile = Object.assign(card.header.picture)
          if (!pictureFile.fileUrl) {
            await pictureFile.save(this.botId)
          }
          if (!pictureFile.fileUrl)
            errorArr.push(
              `${mField} ${this.gT('picture').toLowerCase()} - Card ${cPos}`
            )
        }
      } catch (e) {
        errorArr.push(
          `${mField} ${this.gT('picture').toLowerCase()} - Card ${cPos}`
        )
      }

      if (!this.slider.height) {
        errorArr.push(
          `${mField} ${this.gT('height').toLowerCase()} - Card ${cPos}`
        )
      }

      if (!card.title) {
        errorArr.push(
          `${mField} ${this.gT('text_title').toLowerCase()} - Card ${cPos}`
        )
      }

      if (!card.subtitle) {
        errorArr.push(
          `${mField} ${this.gT('text_subtitle').toLowerCase()} - Card ${cPos}`
        )
      }

      if (errorArr.length) {
        return { error: errorArr }
      }

      if (!card.header.url) card.header.url = pictureFile.fileUrl
      card.header.height = this.slider.height
      delete card.header.picture
      return card
    },
    async addMessage() {
      this.errorMsg = []
      // ERROR HANDLE TO BE DONE
      this.hasError = this.isError()

      if (this.hasError) {
        return
      }

      this.$vs.loading()
      const getDialogsId =
        this.botMakerMessages[0] && this.botMakerMessages[0].dialogSavedId
      const dialogSavedId = 
        (this.userMessageInEdit && this.userMessageInEdit.dialogSavedId) ||
        (this.botMessageInEdit && this.botMessageInEdit.dialogSavedId)

      // on update image

      const botDialogHandler = JSON.parse(JSON.stringify(this.botMessageInEdit))
      const botDialog = botDialogHandler.type

      const cardsArr = this.slider.cardsArr
      const toUpload = []
      this.errorLoader = []
      for (let index = 0; index < cardsArr.length; index++) {
        const card = await this.processCard(cardsArr[index], index)
        if (card) toUpload.push(card)
      }

      if (this.errorLoader.length) {
        setTimeout(() => {
          this.$vs.loading.close()
          this.errorMsg = [...this.errorLoader]
        }, 2000)
        return false
      }

      this.botMessageInEdit.type = new CardMessageType(
        toUpload[0].title + ' ' + toUpload[0].subtitle,
        toUpload,
        botDialog.platforms,
        botDialog._id,
        botDialog.responseId
      )

      this.botMessageInEdit.type.platforms = ['all']

      this.botMessageInEdit.type.name = 'card_array'

      await this.saveValidation()

      this.SAVE_BOT_MESSAGE(this.botMessageInEdit)
        // .then(async () => {
        .then(async response => {
          if (this.botMessageInEdit.isSaved) {
            //this.UPDATE_MESSAGE(this.botMessageInEdit)
            // this.RESET_BOT_MESSAGE()
            this.SET_ACTIVE_CHANNEL_VIEW('all')
            this.RESET_BOT_MESSAGE()
            // this.showPreview = true
            // return response
            //this.$refs.cardItem.showPreviewF()
          } else {
            await this.GET_DIALOGS(dialogSavedId || getDialogsId)
            // this.RESET_BOT_MESSAGE()
            this.SET_ACTIVE_CHANNEL_VIEW('all')
            return response
          }
        })
        .catch(e => {
          alert('ERROR')
          console.log(e)
        })
        .finally(() => {
          this.$vs.loading.close()
        })
    },
    async modifyMessage() {
      this.errorMsg = []
      console.log('trigger modify ::', this.botMessageInEdit)
      this.hasError = this.isError()

      console.log('ERROR::', this.hasError)

      if (this.hasError) {
        return
      }

      this.$vs.loading()
      // const getDialogsId =
      //   this.botMakerMessages[0] && this.botMakerMessages[0].dialogSavedId

      const botDialogHandler = JSON.parse(JSON.stringify(this.botMessageInEdit))
      const botDialog = botDialogHandler.type

      const cardsArr = this.slider.cardsArr
      const toUpload = []
      this.errorLoader = []
      for (let index = 0; index < cardsArr.length; index++) {
        const card = await this.processCard(cardsArr[index], index)
        if (card) toUpload.push(card)
      }

      if (this.errorLoader.length) {
        setTimeout(() => {
          this.$vs.loading.close()
          this.errorMsg = [...this.errorLoader]
        }, 2000)
        return false
      }

      this.botMessageInEdit.type = new CardMessageType(
        toUpload[0].title + ' ' + toUpload[0].subtitle,
        toUpload,
        botDialog.platforms,
        botDialog._id,
        botDialog.responseId
      )

      // on update image
      if (this.botMessageInEdit.isSaved) {
        await this.botMessageInEdit.type.save(this.botId)
      }

      this.botMessageInEdit.type.platforms = [this.activeChannelView]

      await this.saveValidation()

      this.SAVE_BOT_MESSAGE(this.botMessageInEdit)
        .then(async () => {
          console.log('add msg ::')
          this.UPDATE_MESSAGE(this.botMessageInEdit)

          this.SET_ACTIVE_CHANNEL_VIEW('all')
          //this.$refs.cardItem.showPreviewF()
        })
        .catch(error => {
          return error
        })
        .finally(() => {
          this.$vs.loading.close()
        })
    }
  },
  mounted() {
    if (this.botMessageInEdit.type.isCardMessage()) {
      this.updateCard()
    } else {
      this.eraseCard()
    }
  }
}
</script>

<style lang="scss">
.rcs-card {
  .con-input-upload {
    border-radius: 0;
    height: 150px;
  }
  .select-box {
    background-color: white;
    //height: 200px;
    width: 100%;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  .con-input-upload.disabled-upload {
    display: none !important;
  }
  .img-upload {
    width: 100%;
    margin: auto;
    height: 150px;
  }
}
.content-rcs {
  max-width: 360px;
  margin: auto;
  .vs-con-input {
    width: 60%;
  }
  .vs-con-input-label.input-rcs {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    &.title {
      .vs-input--label {
        font-size: 1.1rem;
        font-weight: bold;
      }
    }
    &.subtitle {
      padding-bottom: 15px;
    }
    .vs-input--label {
      width: 30%;
      height: fit-content;
      margin-top: auto;
      margin-bottom: auto;
      color: #737373;
    }
  }
  .text-content {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: #f1f1f1;
  }
  background-color: #e2e3e4;
  border-radius: 20px;
  padding: 2px;
  padding-top: 0;
  overflow: hidden;
  width: 100%;
  .btn-upload-all {
    display: none;
  }
  .con-input-upload .text-input {
    color: #d0d2d3;
    &:before {
      content: '+';
      text-align: center;
      display: block;
      width: 100%;
      font-size: 20px;
    }
  }
  .con-img-upload {
    .img-upload {
      box-shadow: none;
      background: transparent;
    }
    box-shadow: none;
    background: transparent;
  }
  .con-input-upload {
    background-color: transparent;
    border: none;
  }
}

.d-flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-between {
  justify-content: space-between;
}
#addItemSlider {
  cursor: pointer;
}
</style>
